
import { Certificates } from "@/api";
import { TTabEditCertificate } from "@/api/types";
import axios from "axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ModalCreateEditCertificate extends Vue {
  @Prop({ required: true, type: Boolean })
  isShow!: boolean;
  @Prop({ required: false, type: Number })
  certificateId?: number;

  $refs!: {
    form: HTMLFormElement;
  };

  typeCourse = null;

  menu = false;
  certificates: any = [];
  certificateData: TTabEditCertificate = {
    certificateId: "",
    courseName: "",
    contractType: "",
    companyName: "",
    delegateName: "",
    delegatePassport: "",
    dateCertificate: "",
    delegateSurname: ""
  };
  async getDataCertificate() {
    const { data }: any = await axios.get(`/api/certificates/paid`);
    const res: any = data;
    this.certificates = res;
  }
  async getSelectedCertificate(id: any) {
    const data: any = await Certificates.getInternalById(id);
    this.typeCourse = data.category.id;
    if (this.typeCourse === 3) {
      this.certificateData.code = "";
      this.certificateData.capacity = "";
    }
    this.certificateData = { ...data.data };
    this.certificateData.certificateId = data.data.id || null;
  }

  @Watch("certificateId")
  async updCertificateId(newVal: number) {
    if (newVal) {
      await this.getSelectedCertificate(newVal);
    }
  }
  @Watch("isShow")
  updIsShow() {
    this.certificateData = {
      certificateId: "",
      courseName: "",
      contractType: "",
      companyName: "",
      delegateName: "",
      delegatePassport: "",
      dateCertificate: "",
      delegateSurname: ""
    };
    if (this.typeCourse === 3) {
      this.certificateData.code = "";
      this.certificateData.capacity = "";
    }
  }

  async save() {
    if (this.$refs.form.validate()) {
      const formData = {
        courseName: this.certificateData.courseName,
        delegateName: this.certificateData.delegateName,
        delegatePassport: this.certificateData.delegatePassport,
        delegateSurname: this.certificateData.delegateSurname,
        code: this.certificateData.code,
        capacity: this.certificateData.capacity
      };
      this.$emit("save", formData, this.certificateData.certificateId);
    }
  }
  async created() {
    await this.getDataCertificate();
  }
}
