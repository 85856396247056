import { render, staticRenderFns } from "./CertificatePage.vue?vue&type=template&id=0ec80612&scoped=true&"
import script from "./CertificatePage.vue?vue&type=script&lang=ts&"
export * from "./CertificatePage.vue?vue&type=script&lang=ts&"
import style0 from "./CertificatePage.vue?vue&type=style&index=0&id=0ec80612&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec80612",
  null
  
)

export default component.exports