
import { Certificates, Courses } from "@/api";
import { SeparateCompanyCertificate } from "@/api/types";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class CertificatePage extends Vue {
  @Prop({ required: true })
  selectedTab!: number;
  @Prop({ required: true })
  courses!: object;
  @Prop({ required: false })
  editCertificate?: any;
  @Prop({ required: false })
  isEdit?: boolean;
  @Prop({ required: false })
  certificateId?: any;
  @Prop({ required: false })
  isShow?: boolean;
  menu = false;

  certificateData: SeparateCompanyCertificate = {
    certificableId: undefined,
    assessorRegNo: undefined,
    graduated: moment().format("YYYY-MM-DD"),
    companyName: undefined,
    companyEmail: undefined,
    delegates: [{ fullName: undefined, passport: undefined, grade: undefined }],
    code: undefined,
    capacity: undefined,
    dol: undefined,
    price: undefined
  };
  courseData: any = {};
  $refs!: {
    certificateForm: HTMLFormElement;
  };
  async created() {
    await this.getDataCertificate();
  }

  async save() {
    try {
      const valid: any = await this.$refs.certificateForm.validate();
      if (valid) {
        if (this.isEdit) {
          await Certificates.updateCertificateManually(this.certificateId, {
            ...this.certificateData,
            typeId: this.selectedTab + 1,
            certificableType: "App\\Models\\Course"
          });
          this.$emit("save", this.certificateData);
          this.$success(
            "<strong>Success!</strong>Certificates has been updated!"
          );
          this.$emit("close", true);
        } else {
          await Certificates.createCertificateManually({
            ...this.certificateData,
            typeId: this.selectedTab + 1,
            certificableType: "App\\Models\\Course"
          });
          this.$success(
            "<strong>Success!</strong>Certificates has been created!"
          );
          this.$emit("close", true);
        }
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  get dateFormatted() {
    return moment(this.certificateData.graduated).format("MM/DD/YYYY");
  }
  get isLiftingCourses() {
    return this.courseData?.category?.id === 3;
  }

  async getDataCertificate() {
    if (this.isEdit) {
      this.courseData = {};
      this.certificateData.certificableId = this.editCertificate.certificableId;
      await this.getSelectedCourse(Number(this.certificateData.certificableId));
      if (this.isLiftingCourses) {
        this.certificateData.dol = this.courseData.dol;
        this.certificateData.capacity = this.editCertificate.capacity;
        this.certificateData.code = this.editCertificate.code;
      } else {
        delete this.certificateData.dol;
        delete this.certificateData.capacity;
        delete this.certificateData.code;
      }
      this.certificateData.price = this.editCertificate.price;
      this.certificateData.assessorRegNo = this.editCertificate.assessorRegNo;
      this.certificateData.graduated = moment(
        this.editCertificate.graduated,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.certificateData.companyEmail = this.editCertificate.companyEmail;
      this.certificateData.companyName = this.editCertificate.companyName;
      this.certificateData.delegates = [
        {
          fullName: this.editCertificate.fullName,
          passport: this.editCertificate.passport,
          grade: this.editCertificate.grade
        }
      ];
      this.$emit("tab-update", this.editCertificate.typeId);
    }
  }

  addDelegate() {
    this.certificateData.delegates.push({
      fullName: undefined,
      passport: undefined,
      grade: undefined
    });
  }

  removeDelegate(delegateIndex: number) {
    this.certificateData.delegates.splice(delegateIndex, 1);
  }

  async getSelectedCourse(courseId: number | undefined) {
    if (courseId) {
      const currentCourse: any = await await Courses.info(courseId.toString());
      this.courseData = currentCourse;
    }

    if (this.isLiftingCourses) {
      this.certificateData.dol = this.courseData.dol;
    } else {
      delete this.certificateData.dol;
      delete this.certificateData.capacity;
      delete this.certificateData.code;
    }
  }

  validateMinMax(currentVal: any, min: number, max: number) {
    if (currentVal < min || currentVal > max) {
      return undefined;
    }

    return currentVal;
  }

  @Watch("editCertificate")
  async updEditableCertificate() {
    this.certificateData = {
      certificableId: undefined,
      assessorRegNo: undefined,
      graduated: moment().format("YYYY-MM-DD"),
      companyName: undefined,
      companyEmail: undefined,
      delegates: [
        { fullName: undefined, passport: undefined, grade: undefined }
      ],
      code: undefined,
      capacity: undefined,
      dol: undefined,
      price: undefined
    };
    await this.getDataCertificate();
  }

  @Watch("isShow")
  async updIsShow() {
    await this.$refs.certificateForm.reset();
  }
}
