
import { Certificates } from "@/api";
import { Certificate, CertificatesWithPagination } from "@/api/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SeparateList extends Vue {
  filters: any = {
    course: null,
    company: null,
    passport: null,
    typeId: null
  };
  page = 1;
  limit = 10;
  certificates: Array<Certificate> = [];
  certificatesWithPagination: CertificatesWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  options = {};
  headers = [
    {
      text: "Certificates ID",
      value: "certificateId",
      width: "130px"
    },
    {
      text: "Course Name",
      value: "courseName",
      width: "330px"
    },
    {
      text: "Delegate",
      value: "delegateName"
    },
    {
      text: "Passport ID",
      value: "passport"
    },
    {
      text: "Date",
      value: "date"
    },
    {
      text: "Price",
      value: "price"
    },
    {
      text: "Actions",
      value: "controls",
      align: "center",
      sortable: false,
      width: "230px"
    }
  ];
  @Prop({ required: true })
  filtersData!: { companies: any; passport: any; courses: any };

  @Prop()
  certificatesById!: any;

  @Prop({})
  typeId!: number;

  @Prop({ default: false })
  requestUpdate!: boolean;

  @Prop({ required: false })
  editCertificate?: any;

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadCertificates(val.page);
  }

  @Watch("filters", { deep: true })
  async onFiltersChange() {
    await this.loadCertificates();
  }

  @Watch("requestUpdate", { deep: true })
  async onUpdateRequest() {
    await this.loadCertificates(this.page);
  }

  @Watch("editCertificate")
  async updCertificates() {
    this.loadCertificates(this.page);
  }

  dialog() {
    this.$emit("onShowDialog");
  }

  async loadCertificates(page = 1) {
    try {
      const certificatesWithPagination = await Certificates.list(
        page,
        this.limit,
        {
          ...this.filters,
          typeId: this.typeId + 1
        }
      );

      this.certificates = certificatesWithPagination.data || [];
      this.certificates = this.certificates.map((item: Certificate) => {
        const dateArray = item.date.split("-");
        const date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
        return { ...item, date };
      });
      this.certificatesWithPagination = certificatesWithPagination;
    } catch (e) {
      await this.$error(e.errors);
    }
  }

  async onEdit(id: number) {
    this.$emit("load-certificate", id);
  }

  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Certificates.remove(id);
        await this.loadCertificates(this.page);
      } catch (e) {
        await this.$error(e.errors);
      }
    }
  }

  async onDownload(id: number) {
    try {
      const certificate = this.certificates.find((item: any) => item.id === id);
      const certificateId = certificate
        ? `${certificate.certificateId}.zip`
        : "certificate.zip";
      const data = await Certificates.download(id);
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", certificateId);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      await this.$error(e.errors);
    }
  }

  currencyFormat(price: number) {
    return new Intl.NumberFormat("en", {
      minimumFractionDigits: 2
    }).format(price);
  }
}
