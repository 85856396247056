
import { Certificates, Companies, Courses, Delegates } from "@/api";
import { SeparateCompanyCertificate } from "@/api/types";
import CertificateFields from "@/components/certificates/CertificateFields.vue";
import EditCertificatesList from "@/components/certificates/EditCertificateList.vue";
import ModalCreateEditCertificate from "@/components/certificates/ModalEditCertificate.vue";
import PaidCertificatesList from "@/components/certificates/PaidCertificatesList.vue";
import PendingCertificatesList from "@/components/certificates/PendingCertificatesList.vue";
import SeparateList from "@/components/certificates/SeparateList.vue";
import TitleHeader from "@/components/common/TitleHeader.vue";
import { Component, Vue, Watch } from "vue-property-decorator";

import Spinner from "@/components/shared/Spinner.vue";
import axios from "axios";
@Component({
  components: {
    PaidCertificatesList,
    PendingCertificatesList,
    TitleHeader,
    SeparateList,
    CertificateFields,
    EditCertificatesList,
    ModalCreateEditCertificate,
    Spinner
  }
})
export default class CertificatePage extends Vue {
  loading = false;
  tab = 0;
  tabPopup: any = null;
  intTab = 0;
  headersSimple = [{ icon: "mdi-seal", header: "Certificates Manager" }];
  courses = [];
  companies = [];
  delegates = [];
  certificate = [];
  certificates = [];
  needUpdate = false;
  editableCertificate: any = {};
  isEdit = false;
  showDialog = false;
  isDialogEdit = false;
  certificatesById = [];
  filters = {
    course: null,
    company: null,
    delegate: null,
    passport: null,
    typeId: null,
    certificate: null
  };
  certificateId: null | number = null;
  get filtersData() {
    return {
      courses: this.courses,
      companies: this.companies,
      delegates: this.delegates,
      certificate: this.certificate
    };
  }
  async created() {
    this.loading = true;
    try {
      [this.courses, this.companies, this.delegates] = await Promise.all([
        Courses.listWithoutPagination(),
        Companies.listWithoutPagination(),
        Delegates.listWithoutPagination()
      ]);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    } finally {
      this.loading = false;
    }
  }

  async loadCerificateData(certificateId: number) {
    const certificateData = await Certificates.certificateInfo(certificateId);
    this.showDialog = true;
    this.editableCertificate = certificateData.data;
    this.isEdit = true;
    this.certificateId = certificateId;
  }

  backHandler(): void {
    this.$router.push({ name: "Certificate" });
  }

  async createCertificate(tabId: number, intTab: number) {
    if (tabId === 0 && intTab === 2) {
      this.isDialogEdit = true;
      return;
    }
    this.showDialog = true;
    this.editableCertificate = {};
    this.tabPopup = tabId;
    this.isEdit = false;
  }
  @Watch("showDialog")
  updShowDialog(dialog: boolean) {
    if (!dialog) {
      // this.courses = [];
      this.companies = [];
      this.delegates = [];
    }
  }

  @Watch("intTab")
  async updIntTab() {
    if (this.intTab === 0) {
      const { data }: any = await axios.get(`/api/certificates/pending`);
      this.certificates = data;
      return;
    }
    if (this.intTab === 1) {
      const { data }: any = await axios.get(`/api/certificates/paid`);
      this.certificates = data;
    }
    if (this.intTab === 2) {
      const { data }: any = await axios.get(`/api/certificates/edited/all`);
      this.certificates = data;
    }
  }
  @Watch("tab")
  async updateTab() {
    // this.filters = {
    //   course: null,
    //   company: null,
    //   delegate: null,
    //   passport: null,
    //   typeId: null,
    //   certificate: null
    // };
    const type = this.tab + 1;
    if (type > 1) {
      const { data } = await this.axios.get(
        `api/certificates/tab?type_id=${type}`
      );
      this.certificatesById = data;
    }
  }

  hideHandler() {
    this.needUpdate = true;
    this.showDialog = false;
    this.isDialogEdit = false;
    this.certificateId = null;
  }

  saveEditableCertificate(data: SeparateCompanyCertificate) {
    this.editableCertificate = { ...data };
  }
  async putInternalCertificate(data: any, id: number) {
    this.loading = true;
    try {
      await Certificates.UpdInternalCertificate(data, id);
      this.$success("Update success!");
      this.isDialogEdit = false;
      this.filters = {
        course: null,
        company: null,
        delegate: null,
        passport: null,
        typeId: null,
        certificate: null
      };
    } catch (e) {
      this.$error({ error: "bad request" });
    } finally {
      this.loading = false;
    }
  }

  showDialogEdit(data: boolean) {
    this.isDialogEdit = data;
    this.certificateId = null;
  }
  onEdit(id: number) {
    this.isDialogEdit = true;
    this.certificateId = id;
  }
  async exportCertificates() {
    this.loading = true;
    try {
      // const sertificatesToDownload = (this.$refs.paidSertificates as Vue & {
      //   certificates: Certificates[];
      // })?.certificates;

      const filters = { ...this.filters, ...{ status: 1, typeId: 1 } };

      const paideCertificates = (await Certificates.listPaid(1, 9999, filters))
        .data;

      const certificates = (paideCertificates || []).map(sert => sert.id);
      if (certificates.length === 0) {
        this.$error({ error: "<strong>Error! </strong>Nothing to export" });
        return;
      }

      const responce = await Certificates.downloadCertificates({
        certificates
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([responce]));
      const link = document.createElement("a");

      link.href = downloadUrl;
      link.setAttribute("download", "certificates.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.$success(
        "<strong>Success!</strong>Certificates has been downloaded!"
      );
    } catch (e) {
      const err = e as any;

      this.$error({ error: "<strong>Error!</strong>Something went wrong" });
    } finally {
      this.loading = false;
    }
  }
}
