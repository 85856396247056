
import { Certificates } from "@/api";
import { Certificate, CertificatesWithPagination } from "@/api/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PendingCertificatesList extends Vue {
  page = 1;
  limit = 10;
  certificates: Array<Certificate> = [];
  certificatesWithPagination: CertificatesWithPagination = {
    meta: {
      perPage: this.limit
    }
  };
  headers = [
    {
      text: "Certificates ID",
      value: "certificateId",
      width: "130px"
    },
    {
      text: "Course Name",
      value: "courseName",
      width: "160px"
    },
    {
      text: "Contract Type",
      value: "contractType",
      width: "160px"
    },
    {
      text: "Company Name",
      value: "companyName",
      width: "160px"
    },
    {
      text: "Delegate",
      value: "delegateName"
    },
    {
      text: "Passport/ ID",
      value: "passport"
    },
    {
      text: "Date",
      value: "date"
    },
    {
      text: "Actions",
      value: "controls",
      align: "center",
      sortable: false,
      width: "200px"
    }
  ];
  options = {};

  @Prop()
  filters!: any;

  @Watch("options", { deep: true })
  async onOptionsChange(val: any) {
    this.limit = val.itemsPerPage;
    if (this.limit === -1) this.limit = 9999;
    await this.loadCertificates(val.page);
  }

  @Watch("filters", { deep: true })
  async onFiltersChange() {
    await this.loadCertificates();
  }

  async loadCertificates(page = 1) {
    try {
      const filters = { ...this.filters, ...{ status: 0, typeId: 1 } };
      const certificatesWithPagination = await Certificates.listPending(
        page,
        this.limit,
        filters
      );
      this.certificates = certificatesWithPagination.data
        ? certificatesWithPagination.data
        : [];
      this.certificates = this.certificates.map((item: Certificate) => {
        const dateArray = item.date?.split("-");
        const date = `${dateArray[1]}-${dateArray[0]}-${dateArray[2]}`;
        return { ...item, date };
      });
      this.certificatesWithPagination = certificatesWithPagination;
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  async onDelete(id: number) {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Certificates.remove(id);
        await this.loadCertificates(this.page);
      } catch (e) {
        await this.$error(e.errors);
      }
    }
  }
  async onSetPaid(id: number) {
    try {
      await Certificates.setPaid(id);
      await this.loadCertificates(this.page);
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  isEdit(item: any) {
    if (item.edited) {
      return "red lighten-3";
    }
  }
}
